@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}
.enquireButtonContainer {
}
.enquireButtonContainer button {
  padding: 0 35px;
}
.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  /* background-color: var(--matterColor); */
  background-color: var(--marketplaceColorDark);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    /* padding: 0 24px; */
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
.contentContainerFull {
  composes: contentContainer;
  /* max-width: 100%; */
}
.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;
  padding: 0 20px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}
.contentContainerFull .mainContent {
  flex-basis: 100%;
  /* max-width: 100%; */
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    /* border-left-style: solid;
    border-left-width: 1px; */
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}
.bookingPanelEnquire {
  composes: bookingPanel;
  @media (--viewportLarge) {
    margin-top: 64px;
    flex-basis: 0;
    margin-left: 0;
    border-left-width: 0;
    padding-left: 0;

    border-left-style: none;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  /* padding: 0 24px; */

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}
.heading .subtitle {
  font-size: small;

  margin: 0 0 2px 2px;
  line-height: 1;
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  /* padding: 0 24px; */
  margin-bottom: 35px;
  margin-left: 18px;
  margin-right: 18px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  /* padding: 0 24px; */
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  /* padding: 0 24px; */
  margin-bottom: 35px;
  margin-left: 18px;
  margin-right: 18px;
  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  /* padding: 0 24px; */
  margin-bottom: 5px;
  margin-left: 18px;
  margin-right: 18px;
  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  /* padding: 0 24px; */
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.orderedList {
  padding-left: 15px;
}
.videoContainer {
  margin-bottom: 2rem;
  @media (max-width: 767px) {
    margin: 0 1rem;
  }
}

.video {
  width: unset !important;
  height: unset !important;
}

.videoSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 1rem;
  grid-auto-rows: 220px;
}
.financialContainer {
  display: flex;
  justify-content: space-around;
}
.keywordContainer {
  display: flex;
  flex-wrap: wrap;
}
.keyword {
  margin: 0 0.3rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--marketplaceColor);
  border-radius: 4px;
  padding: 0 0.4rem;
  background-color: var(--marketplaceColor);
  color: white;
}
.documentContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.documentLinks {
  border: 1px solid var(--marketplaceColor);
  padding: 0.2rem 0.5rem;
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
.downloadIcon {
  font-size: 28px;
  margin-bottom: 5px;
}
.financial {
  border: 1px solid lightgray;
  padding: 0.2rem 5rem;
  border-radius: 4px;
}
.table {
  border-collapse: collapse;
  width: 100%;
  background-color: #e5e7eb;
  color: black;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}
.tr:nth-of-type(odd) {
  background: white;
}
.th {
  border: 1px solid white;
  padding: 0.5rem 2rem;
  text-align: left;
  border: 1px solid white;
  background-color: var(--marketplaceColor);
  color: white;
  font-weight: 600;
}
.td {
  border: 1px solid white;
  padding: 0.5rem 2rem;
  text-align: left;
  font-size: 16px;
}
.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    /* display: flex; */
    margin-bottom: 31px;
  }
}
.topIconBar {
  display: flex;
  align-items: center;

  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px 0px;
  @media (--viewportLarge) {
    max-width: calc(100vw - 640px);
  }
}
.topIconBarFull {
  composes: topIconBar;
  @media (--viewportMedium) {
    max-width: 100%;
  }
}
.sectionHeading .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px;
  padding-left: 0;
}
.sectionHeading .heading .headingLeft {
  flex-shrink: 0;
  margin-right: 8px;
}
.sectionHeading .heading .headingRight {
  width: 320px;
  margin-left: 8px;
  margin-right: 8px;
}
.sectionHeading .heading h4 {
  /* margin-top: 4px; */
  color: var(--matterColorAnti);
  align-self: center;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
}
.topIconSectionEach p {
  line-height: 1.4;
  font-size: 13px;
  color: black;
}
.sectionHeading .content {
  margin: 8px 0;
  padding: 0 6px;
  text-align: center;
}
.sectionHeading .content p {
  margin-top: 0;
}
.sectionHeading .icon {
  font-size: 25px;
  color: black;
}
.topIconBar .topIconSectionEach {
  border-right: 1px solid var(--matterColorNegative);
  padding: 0 12px;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (--viewportMedium) {
    width: 194px;
  }
  margin-bottom: 30px;

  height: 102px;
}
.topIconSectionEach .heading {
  flex-direction: column;
}
.topIconSectionEach:last-child {
  border-left: 0px;
  border-right: 1px solid var(--matterColorNegative);
}
.topIconSectionEach:first-child {
  /* border-left: 0px; */
  /* border-left: 1px solid var(--matterColorNegative); */
}
.readMore,
.readLess {
  color: cornflowerblue;
}
.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.tableWrapper {
  border-radius: 5px !important;
  border: 1px solid #bbb !important;
  border-color: darkblue !important;
  overflow: hidden;
}

.pricingTableWrapper {
  /* border-radius: 10px; */
  border-radius: 5px !important;
  border: 1px solid darkblue;
  overflow: hidden;
  & .table {
    margin: 0;
    & thead tr {
      /* background-color: #3d9be9; */
      border-bottom: 1px solid darkblue;
      & th:first-child {
        border-right: 1px solid darkblue;
      }
    }
    & tbody tr td:first-child {
      border-right: 1px solid darkblue;
    }
    & tbody tr:not(:last-child) {
      border-bottom: 1px solid darkblue;
    }
  }
  & .not_available {
    background-color: hsl(0, 0%, 90%);
    & td:first-child {
      border-right: 2px solid #3280c1;
    }
  }
  & .available {
    /* background-color: #3280c1; */
    background-color: #3d9be9;
    color: #fff;
    & td:first-child {
      border-right: 2px solid rgba(255, 255, 255, 0.05);
    }
  }
}

.tableContainer {
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin: 0 1.5rem;
    margin-bottom: 32px;
  }

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
  @media (--viewportLarge) {
    margin-bottom: 52px;
  }
}

.table {
  width: 100%;
  vertical-align: middle;
  text-align: center;
  /* border-color: darkgray !important; */
  /* border: hsl(0, 0%, 70%); */
  & th {
    color: #fff;
    padding: 1rem;
    /* background: hsl(0, 0%, 50%); */
    /* background: #3d9be9; */
    /* background: #3280c1; */
    letter-spacing: 1px;
    text-transform: uppercase;
    /* text-shadow: 1px 0px 0 hsl(240, 100%, 90%); */
  }
}

td {
  padding: 0.5rem 0;
}

.not_available {
  background-color: hsl(0, 0%, 90%) !important;
}

.pricingTableContainer {
  & table {
    /* margin-top: 1rem; */
    & tr {
      & td:first-child {
        /* background-color: darkgray; */
        /* background-color: lightgray; */
        background-color: #3ba6ff29;
      }
      &:not(:last-child) {
        & td:first-child {
          border-bottom: 1px solid darkgray;
          border-bottom-color: darkblue;
        }
      }
    }
    & td {
      border-bottom: 1px solid #aaa;
      border-bottom-color: darkblue;
      &:not(:last-child) {
        border-right: 1px solid #aaa;
        border-right-color: darkblue;
      }
    }

    & tbody tr:last-child td {
      border-bottom: none;
    }
  }
  @media (max-width: 768px) {
    margin: 0 1.5rem;
    margin-bottom: 32px;
    & table {
      & td {
        font-size: 0.8rem;
      }
    }
  }
  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
  @media (--viewportLarge) {
    margin-bottom: 52px;
  }
}

.tableHeader,
.availabilityTableHeader {
  background-color: var(--marketplaceColor);
  /* background: #3d9be9; */
  color: #f9f9f9;
}

.availabililityTableDays {
  background-color: #d5f6ff !important;
}
.oneInOneInPersonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
}
.rootContainer {
  margin: 1.5rem;
  border-bottom: dashed 1px #c8c8c8;
  @media (--viewportMedium) {
    margin: 2rem 0rem;
  }
}
.price {
  color: var(--marketplaceColorDark);
}
