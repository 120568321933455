@import '../../styles/propertySets.css';
.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}
.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0px;
  }
}
.container {
  margin: 0px 1.5rem;
  margin-left: 18px;
  margin-right: 18px;
  @media (--viewportMedium) {
    margin: 0;
  }
  margin-left: 18px;
  margin-right: 18px;
}
.teamsContainer {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}
.teamContainer {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 47%;
    min-width: 400px;
  }
}
.teamImage {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
.bioContainer {
  margin: 10px;
}
.bio {
  margin-top: 0;
  /* overflow: auto; */
}
.icon {
  fill: var(--marketplaceColor);
  font-size: 25px;
  cursor: pointer;
  &:hover {
    fill: var(--marketplaceColorDark);
  }
}
.text {
  font-size: 16px;
  margin: 0px;
}
.memberContainer {
  display: flex;
}
.right {
  margin-left: 2rem;
  line-height: 46px;
  /* margin-top: 5rem; */
}
.memberTitle {
  margin: 0;
}
.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.readMore,
.readLess {
  color: cornflowerblue;
}
